import React from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity } from 'react-native'
import { Text } from 'react-native-normalized'
import styled from 'styled-components/native'
import Icon from '../icon'
import { BLACK, GRAY_LIGHT, WHITE } from '../../constants/colors/brand'

const TouchableContainer = styled(({ stretched, color, borderColor, borderWidth, disabled, small, ...props }) => (
  <TouchableOpacity disabled={disabled} {...props} />
))`
  ${({ stretched, color, borderColor, borderWidth, small }) => `
    border-radius: ${small ? '20px' : '30px'}
    width: ${stretched ? '100%' : 'auto'};
    background-color: ${color};
    border-color: ${borderColor};
    border-width: ${borderWidth};
  `}
`

const ButtonTextContainer = styled(View)`
  justify-content: center;
  align-items: center;
  flex-direction: row;

  ${({ small }) => `
  padding: ${small ? '5px 15px' : '20px'};
  min-height: ${small ? '40px' : '60px'};
`};
`

const ButtonText = styled(({ innerRef, textColor, disabled, small, ...props }) => <Text {...props} />)`
  text-align: center;

  ${({ textColor, disabled, small }) => `
    font-size: ${small ? '14px' : '16px'};
    color: ${textColor};
    font-weight: ${disabled ? 300 : 500};
  `};
`

const ButtonIcon = styled(({ innerRef, textColor, ...props }) => <Icon {...props} />)`
  margin-right: 5px;
`

const ButtonV1 = ({
  children,
  onPress,
  color = WHITE,
  borderColor = WHITE,
  borderWidth = 0,
  stretched = false,
  textColor = BLACK,
  icon,
  set = 'MaterialIcons',
  disabled = false,
  style = {},
  small = false,
  ...rest
}) => (
  <TouchableContainer
    onPress={onPress}
    color={disabled ? GRAY_LIGHT : color}
    borderColor={disabled ? GRAY_LIGHT : borderColor}
    borderWidth={isNaN(borderWidth) ? borderWidth : `${borderWidth}px`}
    stretched={stretched}
    disabled={disabled}
    style={style}
    small={small}
    {...rest}
  >
    <ButtonTextContainer small={small}>
      {icon &&
        (typeof icon === 'string' ? <ButtonIcon name={icon} color={disabled ? WHITE : textColor} set={set} /> : icon)}
      {typeof children === 'string' ? (
        <ButtonText disabled={disabled} textColor={disabled ? WHITE : textColor} small={small}>
          {children}
        </ButtonText>
      ) : (
        children
      )}
    </ButtonTextContainer>
  </TouchableContainer>
)

ButtonV1.propTypes = {
  onPress: PropTypes.func.isRequired,
  color: PropTypes.string,
  stretched: PropTypes.bool,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  set: PropTypes.string
}

export default ButtonV1
