import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { isDesktop, isMobile } from './layout'

const ResponsiveContext = React.createContext({})

export const ResponsiveProvider = ({ children }) => (
  <ResponsiveContext.Provider value={{ isDesktop: isDesktop(), isMobile: isMobile() }}>
    {children}
  </ResponsiveContext.Provider>
)

ResponsiveProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useResponsive = () => useContext(ResponsiveContext)
