import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { View, TouchableOpacity } from 'react-native'
import Text from '../text'
import Icon from '../icon'
import Paper from './paper'
import { GRAY_BORDER, GREEN_SUMMER_GRASS, GRAY_LIGHT } from '../../constants/colors/brand'

const CardButtonText = styled(Text)`
  color: ${({ disabled, color }) => (disabled ? GRAY_LIGHT : color)};
  height: 24px;
  line-height: 24px;
  font-weight: 500;
`

const CardButtonContainer = styled(TouchableOpacity)`
  ${props =>
    props.showBorderLeft
      ? `
    border-left-color: ${GRAY_BORDER};
    border-left-width: 1px;
  `
      : ''} padding: 15px 5px;
  flex: 1;
`

const CardButtonContent = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const CardButtonIcon = styled(View)`
  margin-right: 5px;
`

const CardButton = ({ text, icon, color = GREEN_SUMMER_GRASS, disabled = false, index, onPress, testID }) => (
  <CardButtonContainer testID={testID} onPress={onPress} showBorderLeft={index && index > 0} disabled={disabled}>
    <CardButtonContent>
      {icon && (
        <CardButtonIcon>
          <Icon {...icon} color={disabled ? GRAY_LIGHT : icon.color || color} />
        </CardButtonIcon>
      )}
      <CardButtonText color={color} disabled={disabled}>
        {text}
      </CardButtonText>
    </CardButtonContent>
  </CardButtonContainer>
)

CardButton.propTypes = {
  text: PropTypes.string.isRequired,
  index: PropTypes.number,
  icon: PropTypes.object,
  color: PropTypes.string,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  testID: PropTypes.string
}

const ButtonsContainer = styled(View)`
  flex-direction: row;
  width: 100%;
  border-top-width: 1px;
  border-top-color: ${GRAY_BORDER};
  margin-bottom: -5px;
  margin-top: 10px;
`

const Card = ({ children, elevation = 1, buttons = [], rounded = true, ...props }) => (
  <Paper rounded={rounded} elevation={elevation} {...props}>
    {children}

    {buttons.length > 0 && (
      <ButtonsContainer>
        {buttons.map((button, index) => (
          <CardButton key={index} {...button} index={index} />
        ))}
      </ButtonsContainer>
    )}
  </Paper>
)

Card.propTypes = {
  children: PropTypes.node,
  elevation: PropTypes.number,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.object,
      color: PropTypes.string,
      onPress: PropTypes.func
    })
  ),
  rounded: PropTypes.bool
}

export default Card
