import { Settings as LuxonSettings } from 'luxon'
import i18nJS from 'i18n-js'
import { flow, types, getRoot, unprotect, protect } from 'mobx-state-tree'
import updateLuxonInstances from './update_luxon_instances'
import { languageCodes } from '../constants/locales'
import { Platform } from 'react-native'
import loadIntlPolyfill from './intl_polyfill'
import { REMOTE, CACHED, LOCAL } from './sources'

const createI18nStore = ({ getTranslations, localTranslations, onMissingTranslation, afterCreate }) => {
  const i18nStore = types
    .model('i18nStore', {
      loading: types.optional(types.boolean, false),
      source: types.maybeNull(types.enumeration([REMOTE, CACHED, LOCAL])),
      country: types.maybeNull(types.string),
      locale: types.optional(types.string, 'en'),
      availableLocales: types.optional(types.array(types.string), ['en'])
    })
    .volatile(self => ({
      i18n: i18nJS
    }))
    .views(self => ({
      t(name, values) {
        return self.i18n.t(name, { locale: self.locale, ...values })
      },
      lookup(name, values) {
        return self.i18n.lookup(name, { locale: self.locale, ...values })
      }
    }))
    .actions(self => ({
      afterCreate() {
        self.i18n.defaultLocale = 'en'
        self.i18n.fallbacks = true
        self.i18n.translations = localTranslations

        const originalMissingTranslationBehaviour = self.i18n.missingTranslation.bind(self.i18n)
        self.i18n.missingTranslation = (scope, options) => {
          if (self.source === REMOTE) {
            onMissingTranslation(scope, options)
          }
          return originalMissingTranslationBehaviour(scope, options)
        }

        loadIntlPolyfill('en')
        afterCreate(self)
      },

      update: flow(function * (country, locales, locale = locales[0]) {
        if (self.country !== country) {
          self.source = null
          self.loading = false
        }

        if (self.loading) {
          return
        }

        self.loading = true
        self.country = country
        self.availableLocales = locales
        self.setLocale(locale)

        try {
          const { translations, source } = yield getTranslations({ country, locales })

          // It's possible that above network request has stalled and user has changed country since then
          if (self.country !== country) {
            return
          }

          self.i18n.translations = translations
          self.source = source
          self.loading = false
        } catch (e) {
          console.error(e)
        }

        yield loadIntlPolyfill(country)
      }),

      reload: () => !!self.country && !!self.locale && self.update(self.country, self.availableLocales, self.locale),

      setLocale(locale) {
        self.i18n.locale = locale
        self.locale = locale

        const m = locale.match(/([a-z]+)_en$/)
        if (m && m[1]) {
          self.i18n.locales[locale] = [locale, m[1], 'en']
        } else {
          self.i18n.locales[locale] = [locale, 'en']
        }

        const languageCode = languageCodes[locale]
        LuxonSettings.defaultLocale = languageCode

        if (Platform.OS === 'web') {
          window.document.documentElement.lang = languageCode
        }

        const root = getRoot(self)
        unprotect(root)
        updateLuxonInstances(root, languageCode)
        protect(root)
      }
    }))

  return i18nStore
}

export default createI18nStore
