export const COLOR_PRIMARY = '#52B68D'
export const COLOR_LIGHT_PRIMARY = '#62bc97'
export const COLOR_ERROR = '#ff7275'
export const COLOR_DISABLED_PRIMARY = '#c2e1d2'
export const COLOR_DEPRIORITIZED_PRIMARY = '#E5EEE9'
export const COLOR_SECONDARY = '#999999'
export const COLOR_BACKGROUND = '#FAFAFA'
export const COLOR_BACKGROUND_LIGHT = '#f3f3f3'
export const COLOR_BACKGROUND_DARK = '#e2e2e2'
export const COLOR_BACKGROUND_LABEL_GREEN = '#cce7dc'
export const COLOR_BACKGROUND_LABEL_RED = '#fddcdc'
export const COLOR_LIGHT_ICON = '#cccccc'
export const COLOR_INPUT_LIGHT_BORDER = '#cccccc'
export const COLOR_MESSAGE_ALT = '#e9e9e9'
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_BLACK = '#000000'
export const COLOR_TEXT_PRIMARY = '#576169'
export const COLOR_TEXT_CODE = '#dac269'
export const COLOR_TEXT_SECONDARY = '#666666'
export const COLOR_TEXT_WARNING = '#de6063'
export const COLOR_TEXT_LIGHT = '#bdbfbf'
export const COLOR_TEXT_LIGHT_GREY_PURPLE = '#d3d9e6'
export const COLOR_ACTION = '#FF7275'
export const COLOR_ACTION_LIGHT = '#ff898b'
export const COLOR_ACTION_DISABLED = '#FF9C9E'
export const COLOR_ACTION_BLUE = '#83bfd8'
export const COLOR_LIGHT_BLUE = '#d1e6ef'
export const COLOR_RATING = '#DAC268'
export const COLOR_SHADOW = '#000000'
export const COLOR_BORDER = '#e8e8e8'
export const COLOR_BORDER_INPUT = '#E0E7EE'
export const COLOR_TRANSPARENT = 'transparent'
export const COLOR_BACKGROUND_LIGHT_GREEN = '#a9dbc6'
export const COLOR_PURPLE = '#434882'
export const COLOR_CHECKBOX = '#8f8f8f'
export const COLOR_LIGHT_BORDER = 'rgba(204, 204, 204, 0.41)'
export const COLOR_DARK_BORDER = '#979797'
export const COLOR_CLEANING_PRODUCTS = '#858c92'
export const COLOR_PREMIUM = '#689E85'
export const COLOR_PREMIUM_DARK = '#365245'

export const COLOR_BACKGROUND_BUTTON = '#ff7376'
export const COLOR_BORDER_BUTTON = '#009cec'

export const DOT_COLOR = '#d8d8d8'
export const PROGRESS_BAR = '#878bad'

export const COLOR_WARNING = '#ebc609'
export const COLOR_USP_BLUE = '#79c4ce'
