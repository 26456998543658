import ReactPropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { isStateTreeNode, getType } from 'mobx-state-tree'
import { DateTime } from 'luxon'

const CustomTypes = {
  luxon: (function() {
    const fn = (props, propName, componentName) => {
      const prop = props[propName]

      if (typeof props[propName] !== 'undefined' && props[propName] !== null && !(prop instanceof DateTime)) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. \
Expected luxon object, got ${typeof prop}.`
        )
      }
    }
    fn.isRequired = (props, propName, componentName) =>
      typeof props[propName] === 'undefined' || props[propName] === null
        ? new Error(`Missing prop ${propName} in props`)
        : fn(props, propName, componentName)
    return fn
  })(),
  modelOf(type) {
    const fn = (props, propName) => {
      if (!type || typeof type.is !== 'function') {
        return new Error(`Invalid type passed to modelOf, expected MST type, got ${typeof type}`)
      }
      if (
        typeof props[propName] === 'undefined' ||
        props[propName] === null ||
        (isStateTreeNode(props[propName]) && type.is(props[propName]))
      ) {
        return null
      }
      return new Error(`Invalid value for prop ${propName}, expected ${type.name} got ${getType(props[propName]).name}`)
    }
    fn.isRequired = (props, propName) =>
      typeof props[propName] === 'undefined' || props[propName] === null
        ? new Error(`Missing prop ${propName} in props`)
        : fn(props, propName)
    return fn
  },
  style: ReactPropTypes.oneOfType([ReactPropTypes.object, ReactPropTypes.array])
}

export default { ...ReactPropTypes, ...MobxPropTypes, ...CustomTypes }
