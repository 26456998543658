import { Info } from 'luxon'

const LOCALES = {
  au: () => [import('intl/locale-data/jsonp/en-AU')],
  ch: () => [import('intl/locale-data/jsonp/de-CH'), import('intl/locale-data/jsonp/fr-CH')],
  de: () => [import('intl/locale-data/jsonp/de-DE')],
  en: () => [import('intl/locale-data/jsonp/en-US')],
  fr: () => [import('intl/locale-data/jsonp/fr-FR')],
  ie: () => [import('intl/locale-data/jsonp/en-IE')],
  it: () => [import('intl/locale-data/jsonp/it-IT')],
  nl: () => [import('intl/locale-data/jsonp/nl-NL')],
  sg: () => [import('intl/locale-data/jsonp/en-SG')],
  uk: () => [import('intl/locale-data/jsonp/en-GB')]
}

const getLocaleData = async language => {
  const promises = LOCALES[language] ? LOCALES[language]() : []
  return Promise.all(promises.concat(LOCALES.en))
}

export default async language => {
  if (!Info.features().zones) {
    window.IntlPolyfill = (await import('intl')).default
    await getLocaleData(language)
    window.Intl = window.IntlPolyfill
    window.Intl.__disableRegExpRestore() // @see https://github.com/andyearnshaw/Intl.js/issues/256

    // This needs to be loaded after polyfill has been initialized
    const DateTimeFormat = (await import('date-time-format-timezone')).default
    window.IntlPolyfill.DateTimeFormat = DateTimeFormat
  }
}
