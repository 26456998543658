import React from 'react'
import PropTypes from '../../prop_types'
import styled from 'styled-components/native'
import { View } from 'react-native'
import Paper from './paper'
import Icon from '../icon'
import { BLACK } from '../../constants/colors/brand'
import Touchable from './touchable'

const CardContainer = styled(Paper)`
  flex-direction: row;
  width: 100%;
  align-items: center;
`

const CardContent = styled(View)`
  flex-grow: 1;
  padding: ${props => (props.padded ? '5px 15px' : '5px')};
`

const CardChevron = styled(View)`
  align-items: center;
  justify-content: center;
`

const NavigationCard = ({
  children,
  elevation = 1,
  chevronLeft = false,
  chevronRight = false,
  padded = false,
  onPress,
  style = {},
  cardContainerStyle = {},
  cardContentStyle = {},
  chevronColor = BLACK,
  ...rest
}) => {
  const WrapperComponent = onPress ? Touchable : View

  return (
    <View style={style} {...rest}>
      <WrapperComponent onPress={onPress}>
        <CardContainer rounded elevation={elevation} style={cardContainerStyle} data-component-name='CardContainer'>
          {chevronLeft && (
            <CardChevron data-component-name='ChevronLeft'>
              <Icon name='navigate-before' color={chevronColor} />
            </CardChevron>
          )}
          <CardContent padded={padded} style={cardContentStyle}>
            {children}
          </CardContent>
          {chevronRight && (
            <CardChevron data-component-name='ChevronRight'>
              <Icon name='navigate-next' color={chevronColor} />
            </CardChevron>
          )}
        </CardContainer>
      </WrapperComponent>
    </View>
  )
}

NavigationCard.propTypes = {
  children: PropTypes.node,
  elevation: PropTypes.number,
  chevronLeft: PropTypes.bool,
  chevronRight: PropTypes.bool,
  padded: PropTypes.bool,
  onPress: PropTypes.func,
  style: PropTypes.style,
  cardContainerStyle: PropTypes.style,
  cardContentStyle: PropTypes.style,
  chevronColor: PropTypes.string
}

export default NavigationCard
