import React from 'react'
import PropTypes from 'prop-types'
import ButtonV1 from './button_v1'
import ButtonV2 from './button_v2'

const Button = ({ version = 1, ...rest }) => {
  if (version === 2) {
    return <ButtonV2 {...rest} />
  }
  return <ButtonV1 {...rest} />
}

Button.propTypes = {
  version: PropTypes.oneOf([1, 2])
}

export default Button
