import { View } from 'react-native'
import styled from 'styled-components/native'
import PropTypes from 'prop-types'
import { WHITE } from '../../constants/colors/brand'

// TODO: normalize styling between web, iOS and Android (they render differently)
const Paper = styled(View)`
  border-radius: 8px;
  background-color: ${WHITE};
  padding: 10px;
  ${props =>
    props.elevation ? `box-shadow: 0 ${props.elevation}px 10px rgba(0, 0, 0, ${props.elevation * 0.1})` : ''};
  ${props => (props.rounded ? 'border-radius: 5px' : '')};
`

Paper.propTypes = {
  elevation: PropTypes.number,
  rounded: PropTypes.bool
}

export default Paper
