export const languageCodes = {
  au: 'en-AU',
  ch: 'de-CH',
  ch_de: 'de-CH',
  ch_fr: 'fr-CH',
  ch_en: 'en-US',
  de: 'de-DE',
  de_en: 'en-US',
  en: 'en-US',
  fr: 'fr-FR',
  ie: 'en-IE',
  it: 'it-IT',
  nl: 'nl-NL',
  nl_en: 'en-US',
  sg: 'en-SG',
  uk: 'en-GB'
}
