import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import styled from 'styled-components/native'
import { BLACK } from '../../constants/colors/brand'

export const SETS = {
  MATERIAL_ICONS: 'MaterialIcons',
  MATERIAL_COMMUNITY_ICONS: 'MaterialCommunityIcons'
}
const IconContainer = styled(View)`
  width: 24px;
  height: 24px;
  justify-content: center;
`

const Icon = ({ name, size = 24, color = BLACK, style = {}, set = SETS.MATERIAL_ICONS, innerRef, ...rest }) => {
  const sizeStyle = { width: size, height: size }

  switch (set) {
    case SETS.MATERIAL_COMMUNITY_ICONS:
      return (
        <IconContainer style={[sizeStyle, style]} ref={innerRef}>
          <MaterialCommunityIcons name={name} size={size} color={color} {...rest} />
        </IconContainer>
      )
    case SETS.MATERIAL_ICONS:
    default:
      return (
        <IconContainer style={[sizeStyle, style]} ref={innerRef}>
          <MaterialIcons name={name} size={size} color={color} {...rest} />
        </IconContainer>
      )
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  set: PropTypes.oneOf([SETS.MATERIAL_ICONS, SETS.MATERIAL_COMMUNITY_ICONS, undefined, null]),
  innerRef: PropTypes.func
}

export default Icon
