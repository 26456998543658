import createI18nStore from './store'
import createGetTranslations from './create_get_translations'

/**
 * Returns URL to the locale files for specific country
 * @callback localesUrl
 * @param {string} Country
 */
/**
 * Logging function for debugging
 * @callback debug
 * @param {string} Debug message
 * @param {object} Additional debugging data
 */
/**
 * Function that is called when a translation is missing (for monitoring)
 * @callback onMissingTranslation
 * @param {string} key Translation key
 * @param {object} opts Additional options (including locale)
 */
/**
 * Fetch offline cache (async)
 * @callback getOfflineCache
 * @returns {Promise<object>} Offline cache
 */
/**
 * Store offline cache (async)
 * @callback setOfflineCache
 * @param {object} Offline cache
 * @returns {Promise<object>}
 */
/**
 * Returns default implementations of offline cache, sentry monitoring and refetching on network change
 *
 * @param {object} Library constructors for AsyncStorage, NetInfo and Sentry
 * @returns {object} Functions to pass to createI18nStore()
 */
export const defaultImplementations = ({ debug, AsyncStorage, NetInfo, Sentry }) => {
  const args = {}

  if (AsyncStorage) {
    Object.assign(args, {
      getOfflineCache: async () => JSON.parse(await AsyncStorage.getItem('translations')),
      setOfflineCache: translations => AsyncStorage.setItem('translations', JSON.stringify(translations))
    })
  }

  if (NetInfo) {
    Object.assign(args, {
      afterCreate: async store => {
        let previousState = await NetInfo.fetch()

        NetInfo.addEventListener(state => {
          if (!previousState.isConnected && state.isConnected) {
            debug('Reconnected to internet, reloading translations')
            store.reload()
          }
          previousState = state
        })
      }
    })
  }

  if (Sentry) {
    const missingTranslationsCache = []

    Object.assign(args, {
      onMissingTranslation: (key, { locale }) => {
        const cacheKey = `${key}-${locale}`

        if (!missingTranslationsCache.includes(cacheKey)) {
          Sentry.captureMessage(`Missing translation for ${key} in ${locale}`, { tags: { report_type: 'translation' } })
          console.warn(`Missing translation for ${key} in ${locale}`)
          missingTranslationsCache.push(cacheKey)
        }
      }
    })
  }

  return args
}

/**
 * Creates getTranslation function for fetching translations
 * @param {string} mapKey Project name from h2 phraseapp.yml
 * @param {localesUrl} localesUrl Function that returns URL to locale files for specific country
 * @param {object} localTranslations Inlined translations for minimal UI in case of an error
 * @param {debug} debug Logging function for debugging (optional)
 * @param {onMissingTranslation} onMissingTranslation Function that is called when a translation is missing (for monitoring)
 * @param {(getOfflineCache|null)} getOfflineCache Fetch offline cache (async)
 * @param {(setOfflineCache|null)} setOfflineCache Store offline cache (async)
 *
 * @returns {IModelType}
 */
export default ({
  mapKey,
  localesUrl,
  localTranslations = {},
  debug = () => {},
  onMissingTranslation = () => {},
  getOfflineCache = null,
  setOfflineCache = null,
  afterCreate = () => {}
}) => {
  const getTranslations = createGetTranslations({
    mapKey,
    localesUrl,
    localTranslations,
    debug,
    getOfflineCache,
    setOfflineCache
  })

  return createI18nStore({
    getTranslations,
    localTranslations,
    onMissingTranslation,
    afterCreate
  })
}
