import { getPropertyMembers, getType, isArrayType, isMapType, isModelType, walk } from 'mobx-state-tree'
import { DateTime } from 'luxon'

export default function updateLuxonInstances(rootNode, languageCode) {
  const updateIfLuxon = (value, languageCode) => {
    if (isModelType(value)) {
      return walkTree(value, languageCode)
    }

    return value instanceof DateTime ? value.setLocale(languageCode) : value
  }

  const walkTree = (root, languageCode) => {
    walk(root, node => {
      const type = getType(node)
      if (isModelType(type)) {
        const { properties } = getPropertyMembers(type)
        Object.keys(properties).forEach(key => {
          if (node[key] instanceof DateTime) {
            node[key] = node[key].setLocale(languageCode)
          }
        })
      } else if (isArrayType(type)) {
        node.forEach((value, index) => {
          node[index] = updateIfLuxon(value, languageCode)
        })
      } else if (isMapType(type)) {
        Array.from(node.entries()).forEach(([key, value]) => {
          node[key] = updateIfLuxon(value, languageCode)
        })
      }
    })
  }
  return walkTree(rootNode, languageCode)
}
