import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { View, Pressable } from 'react-native'
import { Text } from 'react-native-normalized'
import styled from 'styled-components/native'
import Icon from '../icon'
import {
  WHITE,
  GREEN_FROSTY_GREEN,
  GRAY_3,
  GREEN_SUMMER_GRASS,
  GREEN_SAGE_TEA,
  GREEN_GROWN_UP_GREEN,
  GRAY_LIGHT
} from '../../constants/colors/brand'

export const BUTTON_TYPES = {
  primary: 'primary',
  secondary: 'secondary'
}
export const BUTTON_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large'
}
export const BUTTON_ICON_POSITIONS = {
  beforeLabel: 'beforeLabel',
  afterLabel: 'afterLabel'
}

const BUTTON_STATES = {
  default: 'default',
  hovered: 'hovered',
  pressed: 'pressed',
  disabled: 'disabled'
}
const BUTTON_HEIGHTS = {
  small: '34px',
  medium: '42px',
  large: '50px'
}
const BUTTON_PADDINGS = {
  small: '8px 60px',
  medium: '12px 60px',
  large: '16px 60px'
}
const BUTTON_COLORS = {
  [BUTTON_TYPES.primary]: {
    [BUTTON_STATES.default]: {
      background: GREEN_SUMMER_GRASS,
      border: GREEN_SUMMER_GRASS,
      text: WHITE
    },
    [BUTTON_STATES.hovered]: {
      background: GREEN_SAGE_TEA,
      border: GREEN_SAGE_TEA,
      text: WHITE
    },
    [BUTTON_STATES.pressed]: {
      background: GREEN_GROWN_UP_GREEN,
      border: GREEN_GROWN_UP_GREEN,
      text: WHITE
    },
    [BUTTON_STATES.disabled]: {
      background: GRAY_LIGHT,
      border: GRAY_LIGHT,
      text: WHITE
    }
  },
  [BUTTON_TYPES.secondary]: {
    [BUTTON_STATES.default]: {
      background: WHITE,
      border: GREEN_SUMMER_GRASS,
      text: GREEN_SUMMER_GRASS
    },
    [BUTTON_STATES.hovered]: {
      background: GREEN_FROSTY_GREEN,
      border: GREEN_SUMMER_GRASS,
      text: GREEN_SUMMER_GRASS
    },
    [BUTTON_STATES.pressed]: {
      background: GREEN_FROSTY_GREEN,
      border: GREEN_SUMMER_GRASS,
      text: GREEN_SUMMER_GRASS
    },
    [BUTTON_STATES.disabled]: {
      background: WHITE,
      border: GRAY_3,
      text: GRAY_3
    }
  }
}

const Container = styled(Pressable)`
  width: ${({ stretched }) => (stretched ? '100%' : 'auto')};
  height: ${({ size }) => BUTTON_HEIGHTS[size]};
  padding: ${({ size }) => BUTTON_PADDINGS[size]};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 7px;
  border-color: ${({ borderColor }) => borderColor};
  border-width: 1px;
  border-style: solid;
  flex-direction: ${({ iconPosition }) => (iconPosition === BUTTON_ICON_POSITIONS.beforeLabel ? 'row' : 'row-reverse')};
  justify-content: center;
  align-items: center;
`
const ButtonLabel = styled(Text)`
  text-align: center;
  color: ${({ textColor }) => textColor};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`
const ButtonIconContainer = styled(View)`
  ${({ iconPosition }) =>
    iconPosition === BUTTON_ICON_POSITIONS.beforeLabel ? 'margin-right: 12px' : 'margin-left: 12px'};
`

const ButtonV2 = ({
  children,
  onPress,
  type = BUTTON_TYPES.primary,
  disabled = false,
  stretched = false,
  size = BUTTON_SIZES.large,
  icon = null,
  set = 'MaterialIcons',
  iconPosition = BUTTON_ICON_POSITIONS.beforeLabel,
  style = {},
  ...rest
}) => {
  const [state, setState] = useState(disabled ? BUTTON_STATES.disabled : BUTTON_STATES.default)

  return (
    <Container
      onPress={onPress}
      onHoverIn={() => setState(BUTTON_STATES.hovered)}
      onHoverOut={() => setState(BUTTON_STATES.default)}
      onPressIn={() => setState(BUTTON_STATES.pressed)}
      onPressOut={() => setState(BUTTON_STATES.default)}
      type={type}
      backgroundColor={BUTTON_COLORS[type][state].background}
      borderColor={BUTTON_COLORS[type][state].border}
      disabled={disabled}
      stretched={stretched}
      size={size}
      style={style}
      iconPosition={iconPosition}
      {...rest}
    >
      <>
        {!!icon &&
          (typeof icon === 'string' ? (
            <ButtonIconContainer iconPosition={iconPosition}>
              <Icon name={icon} color={BUTTON_COLORS[type][state].text} set={set} />
            </ButtonIconContainer>
          ) : (
            icon
          ))}
        {typeof children === 'string' ? (
          <ButtonLabel textColor={BUTTON_COLORS[type][state].text}>{children}</ButtonLabel>
        ) : (
          children
        )}
      </>
    </Container>
  )
}

ButtonV2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onPress: PropTypes.func.isRequired,
  type: PropTypes.oneOf([BUTTON_TYPES.primary, BUTTON_TYPES.secondary]),
  disabled: PropTypes.bool,
  stretched: PropTypes.bool,
  size: PropTypes.oneOf([BUTTON_SIZES.large, BUTTON_SIZES.medium, BUTTON_SIZES.small]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  set: PropTypes.string,
  iconPosition: PropTypes.oneOf([BUTTON_ICON_POSITIONS.afterLabel, BUTTON_ICON_POSITIONS.beforeLabel]),
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default ButtonV2
