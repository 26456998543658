import NunitoSansRegular from './fonts/NunitoSans/NunitoSans-Regular.ttf'
import NunitoSansSemiBold from './fonts/NunitoSans/NunitoSans-SemiBold.ttf'
import NunitoSansBold from './fonts/NunitoSans/NunitoSans-Bold.ttf'

const FONTS = {
  NunitoSans: {
    400: NunitoSansRegular,
    600: NunitoSansSemiBold,
    700: NunitoSansBold
  }
}

export default () => {
  const style = document.createElement('style')
  style.type = 'text/css'

  Object.entries(FONTS).forEach(([name, font]) => {
    const iconFontStyles =
      typeof font === 'string'
        ? `@font-face {
          src: url(${font});
          font-family: ${name};
          font-display: block;
        }`
        : Object.entries(font)
          .map(
            ([weight, url]) => `@font-face {
          src: url(${url});
          font-family: ${name};
          font-weight: ${weight};
          font-display: block;
        }`
          )
          .join('\n')

    if (style.styleSheet) {
      style.styleSheet.cssText += iconFontStyles
    } else {
      style.appendChild(document.createTextNode(iconFontStyles))
    }
  })

  // Inject stylesheet
  document.head.appendChild(style)
}
