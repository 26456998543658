import throttle from 'lodash/throttle'
import merge from 'lodash/merge'
import { REMOTE, CACHED, LOCAL } from './sources'
/**
 * Returns URL to the locale files for specific country
 * @callback localesUrl
 * @param {string} Country
 */
/**
 * Debugging function
 * @callback debug
 * @param {string} Debug message
 * @param {object} Additional debugging data
 */
/**
 * Fetch offline cache (async)
 * @callback getOfflineCache
 * @returns {Promise<object>} Offline cache
 */
/**
 * Store offline cache (async)
 * @callback setOfflineCache
 * @param {object} Offline cache
 * @returns {Promise<object>}
 */
/**
 * Creates getTranslation function for fetching translations
 * @param {string} mapKey Project name from h2 phraseapp.yml
 * @param {localesUrl} localesUrl Function that returns URL to locale files for specific country
 * @param {object} localTranslations Inlined translations for minimal UI in case of an error
 * @param debug
 * @param {(getOfflineCache|null)} getOfflineCache Fetch offline cache (async)
 * @param {(setOfflineCache|null)} setOfflineCache Store offline cache (async)
 * @returns {function({country?: *}): null}
 */
const createGetTranslations = ({
  mapKey,
  localesUrl,
  localTranslations = {},
  debug = () => {},
  getOfflineCache: getOfflineCacheFn = null,
  setOfflineCache: setOfflineCacheFn = null
}) => {
  const getOfflineCache = async () => {
    if (!getOfflineCacheFn) {
      return {}
    }

    try {
      return await getOfflineCacheFn()
    } catch (e) {
      return {}
    }
  }

  const setOfflineCache = async translations => {
    if (setOfflineCacheFn) {
      try {
        await setOfflineCacheFn(translations)
      } catch (e) {}
    }
  }

  const fetchJson = async (url, { cache = true } = {}) => {
    try {
      const opts = cache ? {} : { cache: 'no-cache' }
      const response = await fetch(url, opts)
      return response.json()
    } catch (e) {
      console.error('could not fetch ' + url)
      throw e
    }
  }

  let cacheKey = null

  async function getRemoteTranslations({ country, locales }) {
    const translationsCountry = country || 'de'
    const localesToFetch = locales.concat(['en'])

    debug('fetching translations', { translationsCountry })

    try {
      const mapFile = await fetchJson(localesUrl(translationsCountry) + '/map.json', { cache: false })

      if (!mapFile || !mapFile[mapKey]) {
        debug('invalid map file format', { mapFile })
        throw new Error(`Unable to fetch translations file: ${JSON.stringify(mapFile)}`)
      }

      const map = mapFile[mapKey]

      const promises = localesToFetch.map(async locale => {
        const local = localTranslations[locale]
        if (map[locale]) {
          const url = localesUrl(translationsCountry) + `/${map[locale]}`
          const phrase = map[locale] ? await fetchJson(url) : {}
          return Object.assign({}, local, phrase)
        }
        return local
      })

      const translations = await Promise.all(promises)

      return localesToFetch.reduce((resources, locale, index) => {
        resources[locale] = translations[index]
        return resources
      }, {})
    } catch (error) {
      debug('unable to fetch translations', { error })
      throw error
    }
  }

  const getTranslations = async ({ country, locales }) => {
    cacheKey = country

    try {
      const newRemoteTranslations = await getRemoteTranslations({ country, locales })
      const translations = merge({}, localTranslations, newRemoteTranslations)

      setOfflineCache(translations)

      return { translations, source: REMOTE }
    } catch (e) {
      cacheKey = null
      getTranslationsThrottled.cancel()

      const cachedTranslations = (await getOfflineCache()) || {}
      const translations = merge({}, localTranslations, cachedTranslations)
      const source = Object.keys(cachedTranslations).length > 0 ? CACHED : LOCAL

      return { translations, source }
    }
  }

  // Do not refetch translations more often than once per 1 hour
  const getTranslationsThrottled = throttle(getTranslations, 3600000, { trailing: false })

  return ({ country, locales }) => {
    if (cacheKey !== country) {
      getTranslationsThrottled.cancel()
    }
    return getTranslationsThrottled({ country, locales })
  }
}

export default createGetTranslations
