import { Platform } from 'react-native'
import { Text as BaseText } from 'react-native-normalized'
import styled from 'styled-components/native'
import { GRAY_1 } from '../constants/colors/brand'

const Text = styled(BaseText)`
  ${Platform.select({
    android: 'font-family: Roboto',
    web: "font-family: 'NunitoSans, Lato'"
  })}
  color: ${props => props.theme.textColor || GRAY_1};
`
export default Text
